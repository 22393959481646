import React, { useState } from 'react';
import { emailValidation } from '../../utils';
import { accessCode } from '../../common';
import { grantLiteAccess }  from '../../api/api';
import analytics from '../../mixpanel/analytics';

export function LiteAccessComponent() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [info, setInfo] = useState('');

    const getAccess = async () => {
        if(emailValidation(email)){
            setError('');
            setInfo('');
            try {
                const response = await grantLiteAccess(email);

                switch(response.data){
                    case accessCode.AccessExists:
                        setInfo('На даний момент на вашому акаунті є доступ.');
                    break;
                    case accessCode.AccessGranted:

                        analytics.track(`Отримано доступ до LITE версії. Пошта: ${email}`);
                        setInfo('Доступ надано. Оновіть вікно платформи Libertex.');
                    break;
                    case accessCode.Error:
                        setError('Сталася помилка. Спробуйте пізніше.');
                    break;
                    case accessCode.UserNotFound:
                        setInfo('Даної пошти не знайдено. Перевірте чи введена правильна пошта. Ініціалізуйте PROFITI на платформі Libertex (Зайдіть на плотформу та запустіть плагін).');
                    break;
                    default:
                        setError('Сталася помилка.');
                }
            }catch(er){
                setError(`Сталася помилка. ${er}`);
            }
        }else{
            setError('Неправильний формат пошти.');
        }
    }

    const onEmailEnter = (ev) => {
        setEmail(ev.target.value);
    }
    return (<div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
            <div className="modal__content">
                <button className="modal__close" type="button" data-bs-dismiss="modal" aria-label="Close"><i className="ti ti-x"></i></button>

                <h4 className="modal__title"><strong>Lite</strong> версія</h4>

                <p className="modal__text">Для отримання безкоштовного доступу до <strong>Lite</strong> версії, введіть вашу пошту та натисніть кнопку <strong>ОТРИМАТИ</strong>. Доступ буде надано на 3 дні автоматично. Доступ можна поновлювати кожні три дні.</p>

                <form action="#" className="modal__form">
                    <div className="form__group">
                        <label className="form__label"></label>
                        <input name="mail" type="text" className="form__input" placeholder="Email" value={email} onChange={onEmailEnter}/>
                        <span className="label-info" style={{
                            display: info !== '' ? 'block' : 'none'
                        }}>{info}</span>
                        <span className="label-error" style={{
                            display: error !== '' ? 'block' : 'none'
                        }}>{error}</span>
                    </div>
                    <button className="form__btn" type="button" onClick={getAccess}>Отримати</button>
                </form>

                <span className="screw screw--big-tl"></span>
                <span className="screw screw--big-bl"></span>
                <span className="screw screw--big-br"></span>
            </div>
        </div>
    </div>);
}