import React from 'react';
import analytics from '../../mixpanel/analytics';

export function PrivacyComponent() {
    const alinaCourseClick = () => {
        analytics.track(`Перехід на Taranenko Trade`);
    }

    return (<div >
        <div className="section section--head info">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-10 offset-xl-1">
                        <div className="section__title info">
                            <h1>Політика конфіденційності для <strong>PROFITI</strong>.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section info">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-10 offset-xl-1">
                        <div className="article">
                            <div className="article__content">
                                <div className="article__meta">
                                    <span className="article__date"><i className="ti ti-calendar-up"></i> 15.10.23</span>
                                </div>
                                <h3>Загальні положення</h3>
                                <p>
                                    Ця політика конфіденційності визначає, як <strong>PROFITI</strong> збирає, використовує та зберігає вашу особисту інформацію при використанні нашого плагіна.
                                </p>
                                <h3>Дані, які ми збираємо</h3>
                                <p>
                                    Для забезпечення функціоналу нашого плагіна та ідентифікації користувачів на сервері, ми збираємо наступні дані:

                                </p>
                                <ul>
                                    <li>Поштова адреса</li>
                                    <li>Ім'я</li>
                                </ul>
                                <h3>Як ми використовуємо ці дані</h3>
                                <p>
                                    Зібрана інформація використовується виключно для ідентифікації користувачів на нашому сервері та забезпечення оптимального функціонування плагіна.
                                </p>
                                <h3>Зберігання даних</h3>
                                <p>Усі зібрані дані зберігаються в безпечній базі даних на AWS (Amazon Web Services). Ми вживаємо всіх можливих заходів безпеки, щоб захистити ваші дані від несанкціонованого доступу, втрати, зміни чи розголошення.</p>
                                <h3>Поділ інформації з третіми сторонами</h3>
                                <p>Ми гарантуємо, що не ділимося вашою особистою інформацією з третіми сторонами. Ваша приватність є нашим пріоритетом.</p>
                                <h3>Ваші права</h3>
                                <p>Ви не маєте можливості змінювати або видаляти свої дані після їх передачі. Якщо у вас є питання або зауваження стосовно цієї політики, будь ласка, звертайтеся до нас за допомогою&nbsp;<span className="bold-text">short.term.assist.suport@gmail.com</span>.</p>
                                <h3>Зміни до цієї політики конфіденційності</h3>
                                <p>Ми можемо оновлювати нашу Політику конфіденційності від часу до часу. Будь-які зміни будуть опубліковані на цій сторінці.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}