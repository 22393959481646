import React from "react";

export function MainInfoComponent() {
    return (
        <div>
            <div className="section mainInfo section--head">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3">
                            <div className="section__title mainInfo">
                                <h1><strong>PROFITI</strong></h1>
                            </div>
                        </div>
                    </div>
                    <div className="step mainInfo" style={{ width: '100%'}}>
                    <div className="row">
                        <div className="col-12">
                            <div className="section__title mainInfo">
                            <p className="mainInfo">Ласкаво просимо до першого українського плагіну для ефективної та безпечної короткострокової торгівлі. <strong>PROFITI</strong> - це не просто інструмент, це ваш союзник у світі фінансів!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="section__title mainInfo">
                                <p>Якщо ви ще не маєте облікового запису на платформі Librtex, реєструйтеся за цим <a className="custom-link" href="https://go.libertex-affiliates.com/visit/?bta=50600&nci=20332" target="_blank" rel="noopener noreferrer">посиланням</a>. Здійснивши покупку будь-якої підписки після реєстрації за нашим посиланням, ви отримаєте на <strong>50%</strong> довший термін дії вашої підписки на зареєстрований акаунт. Для отримання <strong>бонусу</strong> напишіть на нашу пошту (вона вказана нижче).</p>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ width: '100%'}}>
                        <div className="col-12">
                            <div className="section__title mainInfo" >
                                <p>З усіма пропозиціями та питаннями щодо співпраці, будь ласка, звертайтеся на нашу електронну адресу(<strong>profiti.contact@gmail.com</strong>) або в телеграм: <a className="custom-link" href='https://t.me/profiti_info'>тут</a>.</p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div className="section mainInfo">
                <div className="section__title mainInfo">
                    <h3>Основні переваги <strong>PROFITI:</strong></h3>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="step mainInfo">
                                <h3 className="step__title mainInfo">Аутентичність</h3>
                                <p className="step__text mainInfo">Це перший український плагін, який розроблено з урахуванням специфіки ринку та потреб трейдерів.</p>

                                <span className="block-icon block-icon--green">
                                    <i className="ti ti-number-1"></i>
                                </span>
                                <span className="screw screw--lines-bl"></span>
                                <span className="screw screw--lines-br"></span>
                                <span className="screw screw--lines-tr"></span>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="step mainInfo">
                                <h3 className="step__title mainInfo">Інтелектуальні налаштування. </h3>
                                <p className="step__text mainInfo">Створюйте угоди згідно ваших преференцій, а <strong>PROFITI</strong> піклується про оптимальне виконання.</p>

                                <span className="block-icon block-icon--green">
                                    <i className="ti ti-number-2"></i>
                                </span>
                                <span className="screw screw--lines-bl"></span>
                                <span className="screw screw--lines-br"></span>
                                <span className="screw screw--lines-tr"></span>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="step mainInfo">
                                <h3 className="step__title mainInfo">Динамічне відстеження.</h3>
                                <p className="step__text mainInfo">Ваші угоди завжди під контролем завдяки автоматичному відстеженню та реакції на ринкові зміни.</p>

                                <span className="block-icon block-icon--green">
                                    <i className="ti ti-number-3"></i>
                                </span>
                                <span className="screw screw--lines-bl"></span>
                                <span className="screw screw--lines-br"></span>
                                <span className="screw screw--lines-tr"></span>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="step mainInfo">
                                <h3 className="step__title mainInfo">Гнучкість налаштувань.</h3>
                                <p className="step__text mainInfo"><strong>PROFITI</strong> автоматично реагує на просадки, здійснює реінвестування, коригує мультиплікатор і забезпечує беззбитковість угод.</p>

                                <span className="block-icon block-icon--green">
                                    <i className="ti ti-number-4"></i>
                                </span>
                                <span className="screw screw--lines-bl"></span>
                                <span className="screw screw--lines-br"></span>
                                <span className="screw screw--lines-tr"></span>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="step mainInfo">
                                <h3 className="step__title mainInfo">Швидкість та ефективність.</h3>
                                <p className="step__text mainInfo">Людина може зреагувати швидко, але <strong>PROFITI</strong> реагує миттєво. Завдяки плагіну, ви завжди в тренді!</p>
                                <span className="block-icon block-icon--green">
                                    <i className="ti ti-number-5"></i>
                                </span>
                                <span className="screw screw--lines-bl"></span>
                                <span className="screw screw--lines-br"></span>
                                <span className="screw screw--lines-tr"></span>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="step mainInfo">
                                <h3 className="step__title mainInfo">Стратегія без ризиків.</h3>
                                <p className="step__text mainInfo"><strong>PROFITI</strong> створено, щоб знизити ваші ризики та допомогти розробити ефективну стратегію торгівлі.</p>

                                <span className="block-icon block-icon--green">
                                    <i className="ti ti-number-6"></i>
                                </span>
                                <span className="screw screw--lines-bl"></span>
                                <span className="screw screw--lines-br"></span>
                                <span className="screw screw--lines-tr"></span>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="step mainInfo">
                                <h3 className="step__title mainInfo">Постійний розвиток.</h3>
                                <p className="step__text mainInfo"><strong>PROFITI</strong> — це живий проект, який постійно вдосконалюється. Ми не стоїмо на місці і регулярно додаємо нові інструменти, щоб максимізувати ваші прибутки та зробити торгівлю ще комфортнішою.</p>

                                <span className="block-icon block-icon--green">
                                    <i className="ti ti-number-7"></i>
                                </span>
                                <span className="screw screw--lines-bl"></span>
                                <span className="screw screw--lines-br"></span>
                                <span className="screw screw--lines-tr"></span>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="step mainInfo">
                                <h3 className="step__title mainInfo">Призначення <strong>PROFITI.</strong> </h3>
                                <p className="step__text mainInfo">Наш плагін не створений для того, щоб замінити трейдера. Його місія — надати вам всі необхідні інструменти для комфортної торгівлі і допомогти вам розробити власну унікальну стратегію.</p>

                                <span className="block-icon block-icon--green">
                                    <i className="ti ti-number-8"></i>
                                </span>
                                <span className="screw screw--lines-bl"></span>
                                <span className="screw screw--lines-br"></span>
                                <span className="screw screw--lines-tr"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section section--head" style={{marginTop: 0}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 ">
                            <div className="section__title mainInfo">
                                <h1>Зробіть свій вибір на користь інновацій з <strong>PROFITI!</strong></h1>
                                <p>Приєднуйтесь до команди лідерів та разом рухайтеся до нових горизонтів у світі фінансів!</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}