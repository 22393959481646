import React from 'react';
import { SiteBackgroundComponent } from '../backgroundAnimations';

export function NotFoundComponent(){
    return (<div className="profiti section section--content">
    <div className="profiti section__content">

        <div className="profiti page-error">
            <h1 className="profiti page-error__title">404</h1>
            <p className="profiti page-error__text">Сторінка з такою адресою відсутня!</p>
            <a href="/" className="profiti page-error__btn">На початок</a>

            <span className="block-icon block-icon--green">
                <i className="ti ti-error-404"></i>
            </span>
            <span className="profiti screw screw--big-tr"></span>
            <span className="profiti screw screw--big-bl"></span>
            <span className="profiti screw screw--big-br"></span>
        </div>

    </div>
    <SiteBackgroundComponent />
</div>);
}