import React, { useState } from "react";

//import './header.css';

export function HeaderComponent() {
    const [showMenu, setShowMenu] = useState(false);
    
    
    return (
        <header className='header'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="header__content">

                            <button className="header__btn" type="button" aria-label="header__nav" onClick={() => setShowMenu(!showMenu)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>

                            <a href="/" className="header__logo">
                                <img src="img/logo.svg" alt="" />
                            </a>

                            <span className="header__tagline"></span>

                            <ul className={`header__nav ${showMenu ? 'header__nav--active' : ''}`} id="header__nav" >
                                <li className="header__dropdown" style={{
                                    visibility: 'hidden'
                                }}>
                                    <a className="dropdown-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Home <i className="ti ti-point-filled"></i></a>

                                    <ul className="dropdown-menu header__dropdown-menu">
                                        <li><a href="index.html">Home style v1</a></li>
                                        <li><a href="index2.html">Home style v2</a></li>
                                    </ul>
                                </li>

                                <li className="header__dropdown" style={{
                                    visibility: 'hidden'
                                }}>
                                    <a className="dropdown-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Earnings <i className="ti ti-point-filled"></i></a>

                                    <ul className="dropdown-menu header__dropdown-menu">
                                        <li><a href="invest.html">Invest</a></li>
                                        <li><a href="token.html">Token</a></li>
                                        <li><a href="affiliate.html">Affiliate</a></li>
                                        <li><a href="contest.html">Contest</a></li>
                                    </ul>
                                </li>

                                <li style={{
                                    visibility: 'hidden'
                                }}>
                                    <a href="about.html">Company</a>
                                </li>

                                <li className="header__dropdown" style={{
                                    visibility: 'hidden'
                                }}>
                                    <a className="dropdown-link dropdown-link--menu" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="ti ti-dots"></i></a>

                                    <ul className="dropdown-menu header__dropdown-menu">
                                        <li><a href="signin.html">Sign in</a></li>
                                        <li><a href="signup.html">Sign up</a></li>
                                        <li><a href="forgot.html">Forgot password</a></li>
                                        <li><a href="404.html">404 Page</a></li>
                                        <li><a href="privacy.html">Privacy policy</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="/education">Навчання</a>
                                </li>

                                <li className="header__dropdown">
                                    <a className="dropdown-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Інструкція<i className="ti ti-point-filled"></i></a>

                                    <ul className="dropdown-menu header__dropdown-menu">
                                        <li><a href="/info">0.1.Х</a></li>
                                    </ul>
                                </li>
                                <li className="header__dropdown">
								<a className="dropdown-link dropdown-link--menu" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="ti ti-dots"></i></a>

								<ul className="dropdown-menu header__dropdown-menu">
									<li><a href="privacy">Політика конфіденційності</a></li>
								</ul>
							</li>
                            </ul>
                            
                            <div className="header__language" style={{
                                display: 'none'
                            }}>
                                <a className="dropdown-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">EN <i className="ti ti-point-filled"></i></a>

                                <ul className="dropdown-menu header__language-menu">
                                    <li><a href="#">English</a></li>
                                    <li><a href="#">Spanish</a></li>
                                    <li><a href="#">French</a></li>
                                </ul>
                            </div>

                            <a href="profile.html" className="header__profile" style={{
                                display: 'none'
                            }}>
                                <i className="ti ti-user-circle"></i>
                                <span>Profile</span>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
