import mixpanel from 'mixpanel-browser';

const analytics = {
    init: () => {
        //if (!isInitialized) {
        //    console.log('init')
            mixpanel.init(process.env.REACT_APP_MY_MIXPANEL_KEY, { persistence: 'localStorage', track_pageview: true });
        //    isInitialized = true;
        //}
    },

    track: (event) => {
        //if (isInitialized) {
        //    console.log('track')
            mixpanel.track(`[PROFITI SITE EVENT]: ${event}`);
       // }else{
            //this.init();
        //}
    }
};

export default analytics;





