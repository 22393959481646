import React from 'react';
import analytics from '../../mixpanel/analytics';

export function EducationComponent() {
    const alinaCourseClick = () => {
        analytics.track(`Перехід на Taranenko Trade`);
    }

    return (<div >
        <div className="section section--head info">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-10 offset-xl-1">
                        <div className="section__title info">
                            <h1>Навчання наше все!</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section info">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-10 offset-xl-1">
                        <div className="article">
                            <div className="article__content">
                                <p>
                                    Трейдинг - це не лише можливість заробити, але й велика відповідальність та ризик. Це вимагає від вас глибоких знань, постійної роботи над собою та дисципліни. Та найголовніше — це тяжка й клопітка робота, де не можна дозволити собі поспішних рішень.
                                </p>
                                <p>
                                    Без правильної стратегії, що базується на знаннях та аналізі, ви швидко втратите свій депозит. Проте не все так безнадійно: на платформі Libertex вас чекає демо-рахунок, де можна відточити свої навички без ризику для реальних коштів, а також підтримка досвідченого менеджера.
                                </p>
                                <p>
                                    Однак лише практика на демо-рахунку та поради менеджера можуть бути недостатніми. Щоб дійсно стати професіоналом, рекомендуємо пройти спеціалізований курс, що допоможе вам розбудовувати власні стратегії та підвищувати свої шанси на успіх у світі трейдингу.
                                </p>
                                <p>Розгляньте даний варіант: <a className="custom-link" href="https://taranenkotrade.com/999" target="_blank" rel="noopener noreferrer" onClick={alinaCourseClick}>Taranenko Trade</a>.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}