import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  //headers: {'Content-Type': 'application/json'}
});

export const grantLiteAccess = (emailAddress) => {
    return instance.get('/user/lite', {
        params: {
          email: emailAddress
        }
      });
  };
  
export const grantTrialAccess = (emailAddress) => {
    return instance.get('/user/trial', {
        params: {
          email: emailAddress
        }
      });
  };