import React, { useEffect } from 'react';
import analytics from './mixpanel/analytics';



import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { HeaderComponent, HomeComponent, InfoComponent, EducationComponent, NotFoundComponent, PrivacyComponent } from './components';

//import dotenv from 'dotenv';
//dotenv.config();

function App() {
  useEffect(() => {
    //analytics.track('Початкова сторінка.');
    analytics.init();
  }, []);

  return (
    <Router>
      <div className='body body--home'>
        <HeaderComponent />
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/info" element={<InfoComponent />} />
          <Route path="/education" element={<EducationComponent />} />
          <Route path="/privacy" element={<PrivacyComponent />} />
          <Route path="*" element={<NotFoundComponent />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
