import {MainInfoComponent, HeroLiteComponent, HeroComponent} from '../';
import { SiteBackgroundComponent } from '../backgroundAnimations';

export function HomeComponent(){
    return(
        <div>
            <MainInfoComponent />
            <HeroComponent />
            <HeroLiteComponent />
            <SiteBackgroundComponent />
        </div>
    );
}